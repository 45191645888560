// wasp-privacy.js

import * as Engine from "./WASP/Engine.js";

document.body.style.overflowX = "hidden";

document.addEventListener("DOMContentLoaded", async () => {
  Engine.updateNavigationState();
  Engine.setupMenuToggle();
  Engine.highlightActiveNavLink();
  Engine.hackedTextEffect();
  Engine.clickListener();
  Engine.handleFader();
  Engine.resizeListener(true);
});
